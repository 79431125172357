'use strict';

angular.module('enervexSalesappApp').service('QuotesService', function(Quote, QuoteComment, Attachment, $stateParams) {
	this.fetchQuotes = fetchQuotes;
	this.getQuoteVersions = getQuoteVersions;
	this.getQuote = getQuote;
	this.newQuote = newQuote;
	this.newQuoteVersion = newQuoteVersion;
	this.deleteQuote = deleteQuote;
	this.getComments = getComments;
	this.createComment = createComment;
	this.removeComment = removeComment;
	this.updateComment = updateComment;
	this.createAttachment = createAttachment;

	function fetchQuotes() {
		return Quote.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		});
	}

	function getQuoteVersions() {
		return Quote.versions({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.quoteId
		});
	}

	function getQuote() {
		var id = $stateParams.quoteId;
		if ($stateParams.version){
			return Quote.version({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: id,
				version: $stateParams.version
			})
		} else {
			return Quote.get({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: id
			});
		}
	}

	function newQuote(quote) {
		return Quote.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, quote)
	}

	function newQuoteVersion(quote) {
		return Quote.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.quoteId
		}, quote);
	}

	function deleteQuote(quote) {
		return Quote.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.quoteId
		});
	}

	function getComments() {
		return QuoteComment.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			quoteId: $stateParams.quoteId,
		});
	}

	function createComment(newComment) {
		return QuoteComment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			quoteId: $stateParams.quoteId
		}, newComment);
	}

	function removeComment(comment) {
		return QuoteComment.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			quoteId: $stateParams.quoteId,
			id: comment._id
		});
	}

	function updateComment(comment) {
		return QuoteComment.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			quoteId: $stateParams.quoteId,
			id: comment._id
		}, comment);
	}

	function createAttachment(fd) {
		return Attachment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, fd);
	}
});
